import React from "react";
import "./App.css";
import Iframe from 'react-iframe'
import PictureBoxes from './components/PictureBoxes'


function App() {
  return (
    <PictureBoxes/>
  );
}

export default App;
