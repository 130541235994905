
import React, { Component, Fragment } from 'react'
import Iframe from 'react-iframe'

export default class PictureBoxes extends Component {
    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
      }
      
      componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
      updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }



    render() {
        return (
            <div>
      <div className="reg">
        <div className="pictureBox" id="one">
          <div className="App-header">
            <div className="intro-basic">
              <div className="title">Hindu Kids Camp</div>
              At Longhorn Hindu Kids Camp, your kids can come spend a day with UT Hindu Students Association! The youth of the Austin community gets a chance to visit the UT campus while learning more about Hindu culture in this day-long adventure. Throughout the camp, kids will have the opportunity to take part in several games, activities, and discussions with similarly-aged children to gain a greater understanding of Hindu culture!

            </div>
          </div>
        </div>

        <div className="pictureBox" id="two">
        {this.state.width > 820 ? 
          <div className="half"></div>
          :
          null
        }
          <div className="half">
            <div className="infoBox">
              <h1>What is Kid's Camp?</h1>
              <p>
              Longhorn Hindu Kids Camp is an annual one-day camp hosted by the University of Texas Hindu Students Association (HSA for short). The camp gives youth from the Austin Hindu community the opportunity to come to the UT campus to learn more about Hinduism and meet other wonderful kids. Longhorn Hindu Kids Camp enables HSA to reach out to and form close ties with families in the Austin community. The day's activities include games, lunch, discussions, and various other Hinduism-related activities. This camp will enable the children to learn more about the importance of their religion and the overarching values it teaches.
              </p>
              <p>
              Every year, we organize the day's activities around a particular theme. Past themes have included Ramayana, the Mahabharata, the Bhagavad Gita, and the like.
              </p>
            </div>
          </div>
        </div>

        <div className="pictureBox" id="three">
            <div class="reg-background">
            <div class="title">Registration</div>
            <div className="embed-form">
            <Iframe url="https://docs.google.com/forms/d/e/1FAIpQLSdeVdPw9qtOo6VwgpZuTO0xfmo4DGkHMPyasVZMPwZ5pdY7Ww/viewform?embedded=true"
                width="700px"
                height="650px"
                id="myId"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                scrolling="yes"
                />
            </div>
            </div>
            
        </div>
        


        {this.state.width > 1080 ? 
        <div className="pictureBox" id="four">
          <div className="half">
            <div className="infoBox">
              <h1>FAQs</h1>
              <h3>How do I know my child/children are safe?</h3>
              <p>
                By state law, we must conform to the rules of the Youth
                Protection Policy (YPP) in hosting this camp. For example, camp
                coordinators are specifically provided training to work with
                children. In addition, we try our best to ensure the appropriate
                parent chaperon to child ratio.
              </p>
              <h3>What will my child/children do?</h3>
              <p>
                The day's activities include games, crafts, lunch, discussions,
                and other Hinduism-related activities (all snacks and pizzas
                will be vegetarian). At the end of the day, your children will
                put on a special performance/demonstration showcasing what they
                learned and crafted.
              </p>
            </div>
          </div>
          <div className="half">
            <div className="infoBox">
              <h3>How do I know my child/children will have fun?</h3>
              <p>
                We have many camp coordinators who have organized camps such as
                HSA Gateway in Bruceville, TX and Hindu Heritage Camp in
                Houston, TX. Our coordinators will make sure to engage every
                child and ensure an enjoyable experience.
              </p>
              <h3>
                How much is the registration? What is included in the fee?
              </h3>
              <p>
                The registration fee is $16 per child. The registration includes
                foods and snacks (all vegetarian) for the whole day along with a
                t-shirt.
              </p>
              <h3>When is the last day to register?</h3>
              <p>
                Registration ends on April 6, 2019 at 11:59 pm. We highly
                recommend you register early.
              </p>
              <h3>Don't see your question here?</h3>
              <p>Please email kidscamp@longhornhsa.org with any questions.</p>
            </div>
          </div>
        </div>

        :
        <Fragment>
        <div className="pictureBox" id="four">
          <div className="half">
            <div className="infoBox">
              <h1>FAQs</h1>
              <h3>How do I know my child/children are safe?</h3>
              <p>
                By state law, we must conform to the rules of the Youth
                Protection Policy (YPP) in hosting this camp. For example, camp
                coordinators are specifically provided training to work with
                children. In addition, we try our best to ensure the appropriate
                parent chaperon to child ratio.
              </p>
              <h3>What will my child/children do?</h3>
              <p>
                The day's activities include games, crafts, lunch, discussions,
                and other Hinduism-related activities (all snacks and pizzas
                will be vegetarian). At the end of the day, your children will
                put on a special performance/demonstration showcasing what they
                learned and crafted.
              </p>
            </div>
          </div>
        </div>
        <div className="pictureBox" id="five">
          <div className="half">
            <div className="infoBox">
              <h3>How do I know my child/children will have fun?</h3>
              <p>
                We have many camp coordinators who have organized camps such as
                HSA Gateway in Bruceville, TX and Hindu Heritage Camp in
                Houston, TX. Our coordinators will make sure to engage every
                child and ensure an enjoyable experience.
              </p>
              <h3>
                How much is the registration? What is included in the fee?
              </h3>
              <p>
                The registration fee is $16 per child. The registration includes
                foods and snacks (all vegetarian) for the whole day along with a
                t-shirt.
              </p>
              <h3>When is the last day to register?</h3>
              <p>
                Registration ends on April 6, 2019 at 11:59 pm. We highly
                recommend you register early.
              </p>
              <h3>Don't see your question here?</h3>
              <p>Please email kidscamp@longhornhsa.org with any questions.</p>
            </div>
          </div>
        </div>
        </Fragment>
        

        }

      </div>
    </div>
        )
    }
}
